import React from 'react';
import './footer.css'; // Optional: Create a CSS file for footer styles

const Footer = () => {
  return (
    <footer className="mt-auto">
      <p className="d-flex flex-column flex-md-row justify-content-md-between">
        <span className="mb-0 order-sm-1 order-2">In The Room, Inc.</span>
        <span className="mb-0 order-sm-2 order-1 text-md-end">Circa &copy; 2024</span>
      </p>
    </footer>
  );
};

export default Footer;
