import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; // Import the custom CSS file
import { Link } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expand="md" className="py-3 header" expanded={expanded}>
      <div className="container mx-0 d-flex justify-content-between align-items-center">
        <Navbar.Brand as={Link} to="/" className='ms-4 pt-3 ms-md-0'>
          <img src={'/images/full-white.svg'} width={'20px'} alt="In The Room Logo" className="logo" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <div className="d-md-none ms-2">
            <ThemeSwitcher />
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          >
            {expanded ? (
              <FontAwesomeIcon icon={faTimes} className="icon" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="icon" />
            )}
          </Navbar.Toggle>
        </div>
        <div className="d-none d-md-flex align-items-center">
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="order-md-3">
          <Nav className="ms-auto text-end pe-3 pe-md-0">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            {/* <Nav.Link as={Link} to="/partner">Partner</Nav.Link> */}
            <Nav.Link as={Link} to="/whitepaper">App</Nav.Link>
            <Nav.Link as={Link} to="/whitepaper">Apply</Nav.Link>
          </Nav>
            <ThemeSwitcher className="me-3 d-none d-md-block" />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
