import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import your pages
import MainPage from './pages/MainPage';
import WhitePaper from './pages/WhitePaper';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Define the routes */}
          <Route path="/" element={<MainPage />} />
          <Route path="/white-paper" element={<WhitePaper />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
