import React from 'react';
// import { Link } from 'react-router-dom';
import ReactRotatingText from 'react-rotating-text';

import './MainPage.css';

import Footer from '../components/footer';
import Header from '../components/Header';
const MainPage = () => {
  const list = [
    'founders',
    'builders',
    'creatives',
    'designers',
    'developers',
    'entrepreneurs',
    'influencers',
    'investors',
  ];

  const handleApplyClick = () => {
    window.location.href = 'mailto:hello@qwest.social?subject=Qwest%20membership%20inquiry&body=I%20want%20to%20join%20qwest%0A%0AFull%20Name:%20%0APhone:%20%0ALocation:%20%0AInstagram:%20';
  };

  return (
    <div>
      <Header />
      <div className="custom-container container">
        <p className="display-4">
          For{' '}
          <span className="dash-word">
            <ReactRotatingText pause={3000} typingInterval={100} deletingInterval={100} items={list} />
          </span>
          <br /> who value a social life.
        </p>
        <h1 className="display-6">
          qwest is a community of doers that explore, connect and vibe—together with the keys to the city.
          <span className="invite-only">
            <button style={{ backgroundColor: 'transparent', border: 'none', color: 'inherit', fontWeight: 800 }} onClick={handleApplyClick}>[ apply ]</button>
          </span>
        </h1>
        <div className="mt-5">
          <img src="/images/yc-logo.png" alt="Y Combinator" className="ycombinator-badge" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MainPage;
