import React from 'react';
// import Footer from '../components/footer';

import './WhitePaper.css'; // Optional: Create a CSS file for styles

const WhitePaper = () => {
  return (
    <div className="white-paper-container">
      <img src="app-screen-shots.png" alt="Qwest Header" className="white-paper-header-image" />
      <header>
        <h1>The White Paper</h1>
      </header>
      <section>
        <h2>Introduction</h2>
        <p>
          We have dedicated over a decade to refining Qwest, a local social platform born from our desire to tackle the
          core issues within the general and online/offline social interaction space. This white paper clarifies our
          motivations, outlines the market landscape, and delineates our plans to improve the way people connect,
          beginning with a focused approach and scaling our solutions over time.
        </p>
      </section>
      <section>
        <h2>Current Social Landscape</h2>
        <h3>Problems:</h3>
        <ul>
          <li>
            <strong>Broken Outgoing Experiences:</strong> The process of planning social outings can be frustrating, with difficulties
            arising from poor information, availability and cumbersome entry processes, making spontaneous engagements
            less feasible.
          </li>
          <li>
            <strong>The Isolation of Affluence:</strong> Although affluent individuals often have access to a variety of experiences and do
            not face difficulties in planning, they frequently encounter social isolation. Surrounded by individuals who
            view them as opportunities for personal gain, they struggle to form authentic connections. Their interactions
            are predominantly with those not on their economic level, and making genuine connections with peers can be
            challenging.
          </li>
          <li>
            <strong>Global Loneliness:</strong> Despite being more connected than ever, increases in reported loneliness and depression
            highlight the need for more meaningful social interactions.
          </li>
          <li>
            <strong>Language Barriers in a Globalized World:</strong> As more people travel and relocate across borders, language barriers
            emerge as a significant hurdle, complicating the ability to connect meaningfully in new environments. Despite
            the availability of technology that could simplify communication, current solutions are often inadequate,
            leaving a gap that impedes true social integration.
          </li>
        </ul>
      </section>
      <section>
        <h2>The Shift in Social Dynamics</h2>
        <h3>Trends and Predictions:</h3>
        <ul>
          <li>
            <strong>Generational Shift:</strong> With 70% of Gen Z open to sharing their location versus just 7% of Millennials, we see a
            trend toward using technology more transparently to foster online to offline social connections.
          </li>
          <li>
            <strong>Localized Community Focus:</strong> Initially focusing on providing a seamless experience for a specific community
            allows us to refine our offerings and ensure effectiveness before scaling to broader markets.
          </li>
        </ul>
      </section>
      <section>
        <h2>The Role of Social Clubs</h2>
        <h3>Analysis:</h3>
        <ul>
          <li>
            <strong>Value of Social Clubs:</strong> Social clubs like country clubs and fraternities have proven successful in building
            community bonds. They are not a problem but rather a model of engagement that we can learn from.
          </li>
          <li>
            <strong>Innovation in Integration:</strong> Clubs like Soho House, which integrate dining and nightlife, showcase the
            potential for a diverse social experience. These are inspirations for Qwest to provide varied social
            interactions under one umbrella.
          </li>
        </ul>
      </section>
      <section>
        <h2>Qwest: A Proposed Solution</h2>
        <h3>Features and Benefits:</h3>
        <ul>
          <li>
            <strong>Starting Small and Scaling:</strong> By starting with a targeted approach, Qwest will perfect a seamless social
            experience for one community, mastering the intricacies of their needs and preferences. This success will
            form the blueprint for expansion to other communities.
          </li>
          <li>
            <strong>Seamless Integration:</strong> Users will enjoy the ability to discover events, join experiences, check in smoothly,
            meet new people easily, and manage payments, all within one platform, streamlining the social experience.
          </li>
        </ul>
      </section>
      <section>
        <h2>Conclusion</h2>
        <p>
          Qwest is more than just an app; it's a new paradigm for social engagement, starting with a focused community
          to ensure we provide meaningful and impactful experiences before expanding our reach.
        </p>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default WhitePaper;
